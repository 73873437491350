import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import queryString from 'query-string';
import CurrencyFormatter from "../util/CurrencyFormatter";

const ThankYouDonation = ({ location }) => {
  const {session_id} = queryString.parse(location.search);
  
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  useEffect(() => {
    if(!session_id){
      setLoading(false)
      return;
    }

    fetch(`/.netlify/functions/get-checkout-session?session_id=${session_id}`)
    .then(res => res.json())
    .then(response => {
      if(response.success) setData(response)
    })
    .catch(err => console.log(err))
    .finally(() => setLoading(false));
  }, [session_id])

  return (
    <Layout location={location}>
      <Seo title="Thank you!" />
      <div className="aux-page">
        <div className="container w-100 m-auto text-center" style={{maxWidth: 700}}>
          {loading ? (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            session_id && data ? (
              <>
                <h3 className="display-4">{`Thank you for your donation ${data?.customer?.name}!`}</h3>
                <h5 className="mb-0 mt-3">
                  <span>Your donation of </span>
                  <span style={{fontWeight: 'bold'}}><CurrencyFormatter currency={data?.session?.currency} amount={data?.session?.amount_total/100} /></span>
                  <span> has been processed</span>
                </h5>
                <h5>100% of your money will go directly to helping a youth in need, one child at a time.</h5>
              </>
            ) : (
              <div className="alert alert-danger" role="alert">
                No checkout session id found.
              </div>
            )
          )}
        </div>
      </div>
    </Layout>
  )
}

export default ThankYouDonation
